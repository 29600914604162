import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Paper, FormControlLabel, Checkbox } from '@mui/material';

function LoginPage({ setIsLoggedIn, remember }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(false);


    const validCredentials = {
        username: 'david',
        password: 'hidavid'
    };


    // Handle form submission
    const handleLogin = (e) => {
        e.preventDefault();

        // Simple validation
        if (username === validCredentials.username && password === validCredentials.password) {
            remember(rememberMe)
            setIsLoggedIn(true);
            setErrorMessage(''); // Clear error message
        } else {
            setErrorMessage('Invalid username or password');
        }
    };

    return (// Show the login form if not logged in
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <Grid item xs={12} sm={8} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Login
                    </Typography>
                    <form onSubmit={handleLogin}>
                        <TextField
                            label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                        />
                        {errorMessage && (
                            <Typography color="error" variant="body2" gutterBottom>
                                {errorMessage}
                            </Typography>
                        )}


                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                                label="Remember me"
                            />
                        </Grid>


                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Login
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>)

}



export default LoginPage;